$breakpoints: (
  "xs": 340px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  "2xl": 1536px,
);

@function breakpoint($size) {
    @return map-get($breakpoints, $size);
}

.box {
    &-light {
        padding: 1rem;
        background-color: #f7f7f7;
        border: 1px solid black;
        border-radius: 5px;

        @media screen and (max-width: breakpoint(lg)) {
            padding: 5px;
        }
    }
}