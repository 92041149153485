$breakpoints: (
  "xs": 340px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  "2xl": 1536px,
);

@function breakpoint($size) {
    @return map-get($breakpoints, $size);
}



.game-portfolio {
    justify-content: space-between;
    height: 100vh;
    padding: 4rem 1rem 10px;

    @media screen and (min-width: breakpoint(md)) {
      padding-top: 8rem ;
    } 
  
    .grid {  
      /* Menu Items */
      .menu-item {
        position: relative;
        box-shadow: 0 0 2px black;
        overflow: hidden;
        letter-spacing: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s ease;
        
        &:hover {
          transform: scale(1.01);
          cursor: pointer;
        }
        
        &-skills {
          border-radius: 15px 0 15px 0;
          background-color: rgb(25, 25, 30);
          color: white;
          align-items: start;
        }

        &-overlay {
          color: white;
          bottom: 0;
          height: 40%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 15px 0 15px 0;
          background-color: #9c27b0;
          padding: 10px;
          
          @media screen and (min-width: breakpoint(lg)) {
            position: absolute;
            height: 40%;
            right: 0;
            left: 0;
            background-color: #8000806e;
            border: 30px solid #9c27b0;
          }

        }
      }
  
      /* Slider */
      .slider {
        position: relative;
        overflow: hidden;
        border-radius: 15px 0 15px 0;
        box-shadow: 0 0 10px black;


  
        /* Overlay to darken the slides */
        .slider-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1; /* Ensure it sits above the slides */
          pointer-events: none; /* Allow interactions to pass through */
          transition: background 0.3s ease; /* Smooth transition effect */
        }
  
        &:hover .slider-overlay {
          background: rgba(0, 0, 0, 0.2); /* Lighten the overlay slightly on hover */
          cursor: pointer;
        }
  
        /* Slider track for smooth sliding */
        .slider-track {
          display: flex;
          transition: transform 0.5s ease-in-out;
          position: relative; /* Make the track stack below the overlay */
          z-index: 0;
        }
  
        /* Individual slide */
        .slide {
          min-width: 100%; /* Ensure each slide takes full width */
          min-height: 100%; /* Inherit height from parent container */
          position: relative;
  
          img {
            min-width: 100%; /* Make the image width fill the container */
            min-height: 100%; /* Make the image height fill the container */
            object-fit: cover; /* Ensure the image fills the box without stretching */
          }
        }
  
        /* Description overlay */
        .description-overlay {
          position: absolute;
          height: 40%;
          bottom: 0;
          width: 100%;
          background: rgba(0, 0, 0, 0.8);
          color: white;
          text-align: center;
          padding: 20px;
          transform: translateY(100%); /* Hidden by default */
          transition: transform 0.3s ease;
          z-index: 2; /* Ensure it sits above the slider-overlay */
          pointer-events: none; /* Prevent interference with hover detection */
  
          h2 {
            margin: 0 0 10px;
            font-size: 1.5rem;
          }
  
          p {
            margin: 0;
            font-size: 1rem;
          }
        }
  
        &:hover .description-overlay {
          transform: translateY(0); /* Slide up when hovering over the slider */
          pointer-events: auto; /* Enable interaction with the overlay content */
          cursor: pointer;
        }
  
        /* Slider indicator dots */
        .slider-indicator {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          gap: 10px;
          z-index: 2; /* Ensure it sits above the slider-overlay */
  
          .dot {
            width: 10px;
            height: 10px;
            background: #666;
            border-radius: 50%;
            cursor: pointer;
            transition: background 0.3s;
  
            &.active {
              background: #f7f7f7;
            }
          }
        }
      }
    }

    .skill-icon {
      cursor: help;
      border: 1px solid transparent;
      border-radius: 5px;
      padding: 10px;
      transition: all 1s ease;
      &:hover {
          border: 1px solid white;
      }
      &:nth-child(even) {
          animation-delay: 200ms;
      }
      height: 100px;
      width: auto;
      margin: 0.5rem;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      svg {
          width: 90px;
      }
      @media screen and (max-width: 576px) {
          padding: 3px;
          margin: 0.5rem 0;
          height: 60px;
          width: 80px;
      }
  }
}
  