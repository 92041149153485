$breakpoints: (
  "xs": 340px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  "2xl": 1536px,
);

@function breakpoint($size) {
    @return map-get($breakpoints, $size);
}


.game-projects {
    padding: 4rem 1rem 10px;
    height: 100vh;

    @media screen and (min-width: breakpoint(md)) {
        padding-top: 8rem ;
    }
}