@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;700&display=swap');

$breakpoints: (
  "xs": 340px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  "2xl": 1536px,
);

@function breakpoint($size) {
    @return map-get($breakpoints, $size);
}

* {
    font-family: 'Oswald', sans-serif;
}

body {
    background-color: #f7f7f7;
}

h1,h2,h3,h4,h5,h6 {
    margin-bottom: 0.5rem !important;
}

h1 {
    font-size: 1rem !important;
    @media screen and (min-width: #{breakpoint(xs)}) and (max-width: #{breakpoint(xl)}) {
        font-size: 2.2rem !important;
    }
      
      @media screen and (min-width: #{breakpoint(xl)}) {
        font-size: 2.5rem !important;
      }
}
  
  h2 {
    font-size: calc(1rem * 0.8) !important; // 80% of h1
    @media screen and (min-width: #{breakpoint(xs)}) and (max-width: #{breakpoint(xl)}) {
      font-size: calc(2.2rem * 0.8) !important;
    }

    @media screen and (min-width: #{breakpoint(xl)}) {
      font-size: calc(2.5rem * 0.8) !important;
    }
  }
  
  h3 {
    font-size: calc(1rem * 0.75) !important; // 75% of h1
    @media screen and (min-width: #{breakpoint(xs)}) and (max-width: #{breakpoint(xl)}) {
      font-size: calc(2.2rem * 0.75) !important;
    }

    @media screen and (min-width: #{breakpoint(xl)}) {
      font-size: calc(2.5rem * 0.75) !important;
    }
  }
  

  h4 {
    font-size: calc(1rem * 0.70) !important; // 70% of h1
    @media screen and (min-width: #{breakpoint(xs)}) and (max-width: #{breakpoint(xl)}) {
      font-size: calc(2.2rem * 0.70) !important;
    }

    @media screen and (min-width: #{breakpoint(xl)}) {
      font-size: calc(2.5rem * 0.70) !important;
    }
  }


  p, b, a, span {
    font-size: 10px !important;
    @media screen and (min-width: #{breakpoint(xs)}) and (max-width: #{breakpoint(xl)}) {
      font-size: calc(2.2rem * 0.45) !important;
    }

    @media screen and (min-width: #{breakpoint(xl)}) {
      font-size: calc(2.5rem * 0.45) !important;
    }
  }

  p, span {
    font-weight: 200;
  }


small {
font-size: 8px !important;
@media screen and (min-width: #{breakpoint(xs)}) and (max-width: #{breakpoint(xl)}) {
    font-size: calc(2.2rem * 0.3) !important;
}
@media screen and (min-width: #{breakpoint(xl)}) {
    font-size: calc(2.5rem * 0.3) !important;
}
}

.btn {
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid grey;
    box-shadow: 2px 2px 10px #0000005e;

    @media screen and (min-width: breakpoint(md)) and (max-width: breakpoint(lg)) {
        padding: 10px 10px;
    }
    @media screen and (min-width: breakpoint(lg)) and (max-width: breakpoint(xl)) {
        padding: 10px 10px;
        border-radius: 0.5rem;
    }
    @media screen and (min-width: breakpoint(xl)) {
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;;
    }

    &-play {
        background-color: green;
        color: white;
    }

    &-white {
        // background-color: #9c27b0;

        background-color: #f7f7f7;
        color: #000;
        border: 1px solid black;
    
        &:hover {
            box-shadow: none;
            border: 1px solid black;
            background-color: #f1f1f1;
        }
    }

    &-black {
        background-color: black;
        color: white;
        &:hover {
            background-color: #1f1f1f;
            color: white;
        }
    }



    &-bounce {
        height: 100px;
        border-radius: 50%;
        background-color: white;
        color: black;
        font-size: 1rem;
        font-weight: bold;
        text-align: center;
        border: none;
        outline: none;
        cursor: pointer;
        position: relative;
        overflow: hidden; /* Ensures wave effect stays within the button */
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add shadow for visibility */
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        &:hover {
          transform: scale(1.1); /* Slight zoom on hover */
          box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
        }
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 20%;
          height: 20%;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.3); /* Wave color */
          transform: translate(-50%, -50%) scale(0);
          animation: wave 3.5s ease-in-out infinite;
          pointer-events: none; /* Prevent interaction */
        }
        
        @keyframes wave {
            0% {
                transform: translate(-50%, -50%) scale(0);
                opacity: 0.5;
            }
            30% {
                transform: translate(-50%, -50%) scale(5); /* Extend to edges */
                opacity: 0;
            }
            100% {
                transform: translate(-50%, -50%) scale(5); /* Extend to edges */
                opacity: 0;
            }
        }
    }
}

.generic-link {
    text-decoration: underline;
    &:hover {
        color: grey;
        cursor: pointer;
    }
}


// section {
//     margin-bottom: 5rem !important;
// }


#scroll_snap_container {
    max-height: 100vh; /* Set to a specific height to allow scrolling */
    overflow-y: auto; /* Ensure scroll is enabled */
  }