$breakpoints: (
  "xs": 340px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  "2xl": 1536px,
);

@function breakpoint($size) {
    @return map-get($breakpoints, $size);
}

.box {
    &-sticker {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid black;
        padding: 0px 5px;
        border-radius: 5px;
        background-color: #000;
        color: #fff;
        box-shadow: 2px 2px 10px #0000005e;
        font-size: 12px;

        @media screen and (min-width: #{breakpoint(md)}) {
            padding: 5px 10px;
        }
    }
}