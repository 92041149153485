$breakpoints: (
  "xs": 340px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  "2xl": 1536px,
);

@function breakpoint($size) {
    @return map-get($breakpoints, $size);
}

.about-me {
    padding: 4rem 1rem 10px;
    @media screen and (min-width: breakpoint(md)) {
        padding-top: 8rem;
    }
}


.about {
    .overview-box {
      border: 1px solid black;
      border-radius: 15px;
      padding: 5px 10px;
      border-radius: 5px;
      margin-right: 15px;
      background-color: black;
      color: white;
      box-shadow: 2px 2px 10px #0000005e;
    }
    
    
    .skill-icon {
      padding: 10px 10px 5px 10px;
      border: 1px solid black;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      p {
        font-size: 14px;
      }
      svg {
        height: 20px;
        path {
          fill: black;
        }
      }
    }
    
    .language-box {
      padding: 13px;
      border-radius: 15px;
      box-shadow: 5px 5px 10px #bbb8b8;
      @media screen and (max-width: breakpoint(md)) {
        padding: 5px 10px;
        border-radius: 10px;
       }
    }
    
    ul {
      li {
        font-weight: 200;
      }
    }
    small {
      font-weight: 200;
    }
    
    // #navbar {
    //   background-color: black;
    //   color: white;
    //   svg {
    //     path {
    //       fill: white !important;
    //     }
    //   }
    // }
    
    .footer-title {
      position: relative;
        left: 50%;
        display: inline-block;
        transform: translate(-50%);
        margin-bottom: 16px;
        font-size: var(--font_body_medium);
        line-height: 24px;
        text-align: center;
        color: var(--text_primary);
    
        &:before {
          content: "";
          position: absolute;
          left: 110%;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          width: 36px;
          height: 2px;
          background-color: #696969;
          border-radius: 20px;
        }
    
        &:after {
          content: "";
          position: absolute;
          left: -70%;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          width: 36px;
          height: 2px;
          background-color: #696969;
          border-radius: 20px;
        }
    }
  }