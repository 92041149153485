$breakpoints: (
  "xs": 340px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  "2xl": 1536px,
);

@function breakpoint($size) {
    @return map-get($breakpoints, $size);
}


.project-card {
    position: relative;
    box-shadow: 0px 0px 10px 0px #000000;
    background-color: #f7f7f7;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // overflow: hidden;
    border-radius: 0px 10px 0px 10px;

    transition: all 0.3s ease;
    
    &:hover {
        transform: translateY(-7px);
    }

    @media screen and (min-width: #{breakpoint(md)}) {
        border-radius: 0px 20px 0px 20px;
    }

    .type {
        border-left: inset;
        border-bottom: inset;
        border-color: #681876;

        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 10px #9c27b0;
        background-color: #9c27b0;
        color: white;
        text-align: center;
        border-radius: 0px 5px 0px 5px;
        z-index: 1;
        padding: 3px 7px;

        @media screen and (min-width: #{breakpoint(md)}) {
            padding: 5px 10px;
            right: -15px;
            top: -10px;
            transform: rotate(-25deg);
            letter-spacing: 1px;
        }
    }

    .content {
        &-text {
            padding: 0.5rem 0.5rem 0 0.5rem;
            @media screen and (min-width: #{breakpoint(md)}) and (max-width: #{breakpoint(lg)}) {
                padding: 0 1rem 1rem 1rem;
            }
            @media screen and (min-width: #{breakpoint(xl)}) {
                padding: 0 1.5rem 1.5rem 1.5rem;
            }
        }
        &-btn {
            padding: 0 0.5rem 0.5rem 0.5rem;
            @media screen and (min-width: #{breakpoint(md)}) and (max-width: #{breakpoint(lg)}) {
                padding: 0 1rem 1rem 1rem;
            }
            @media screen and (min-width: #{breakpoint(xl)}) {
                padding: 0 1.5rem 1.5rem 1.5rem;
            }
        }
    }

    .img-wrapper {
        height: 60px;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 20px black;
        border-radius: 0px 10px 0px 10px;


        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
        }



        @media screen and (min-width: #{breakpoint(xs)}) and (max-width: #{breakpoint(sm)}) {
        }
        @media screen and (min-width: #{breakpoint(sm)}) and (max-width: #{breakpoint(md)}) {
        }
        @media screen and (min-width: #{breakpoint(md)}) and (max-width: #{breakpoint(lg)}) {
            height: 150px;
        }
        @media screen and (min-width: #{breakpoint(lg)}) {
            height: 200px;
        }
    }
}