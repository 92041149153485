@import url( 'https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap');

$breakpoints: (
  "xs": 340px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  "2xl": 1536px,
);

@function breakpoint($size) {
    @return map-get($breakpoints, $size);
}


.home {
    #typing {
        font-size: 1.2rem;
        letter-spacing: 0;
        font-family: 'Gloria Hallelujah', cursive;
    }
    
    .bg-gradient {
        position: absolute;
        right: 0;
        left: 40%;
        background: linear-gradient(101deg, transparent 52%, #0d0d0d 52%);
        height: 110vh;
        top: 0;
        z-index: 0;
    }
    &#scroll_snap_container {
        height: 100vh;
        width: 100%;
        overflow-y: scroll;
        scroll-snap-type: y mandatory;
        background-color: #f7f7f7;
        color: black;
        transition: all 1s ease;
    
        &.bg-dark {
            background-color: #131313;
            color: white;
        }
    
        section {
            position: relative;
            height: 100vh;
            width: 100vw;
            overflow: hidden;
            display: flex;
            align-items: center;
            scroll-snap-align: start;
            padding: 50px 1rem 10px;
            justify-content: space-around;


            // background-image: url("../src/assets/images/Logo.png");
            &:nth-child(even) {
                background-color: #0d0d0d;
                color: white;
            }
    
            > div {
                z-index: 1;
                max-width: 1280px;
            }
    
            .skill-icon {
                cursor: help;
                border: 1px solid transparent;
                border-radius: 5px;
                padding: 10px;
                transition: all 1s ease;
                &:hover {
                    border: 1px solid white;
                }
                &:nth-child(even) {
                    animation-delay: 200ms;
                }
                height: 100px;
                width: auto;
                margin: 0.5rem;
    
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                svg {
                    width: 90px;
                }
                @media screen and (max-width: 576px) {
                    padding: 3px;
                    margin: 0.2rem 0;
                    height: 45px;
                    width: 70px;
                }
            }
            @media screen and (min-width: breakpoint(md)) {
                justify-content: center;
            }
        }
    }
    
    .icon-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        @media screen and (max-width: 340px) {
            flex-wrap: nowrap;
            overflow-x: scroll;
            width: 100vw;
        }
        @media (orientation: landscape) and (max-width: 576px) {
            flex-wrap: nowrap;
            overflow-x: scroll;
            width: 100vw;
        }
    }
    
    
    .skills-overview {
        .web-skills, .game-skills {
            background-color: rgb(25 25 30);
            padding: 0.5rem;
            box-shadow: 0 0 5px #363636;
            border-radius: 5px;
        }

        @media screen and (min-width: #{breakpoint(xs)}) and (max-width: #{breakpoint(sm)}) {
        }
        @media screen and (min-width: #{breakpoint(sm)}) and (max-width: #{breakpoint(md)}) {
        }
        @media screen and (min-width: #{breakpoint(md)}) and (max-width: #{breakpoint(lg)}) {
        }
        @media screen and (min-width: #{breakpoint(lg)}) and (max-width: #{breakpoint(xl)}) {
        }
        @media screen and (min-width: #{breakpoint(xl)}) {
            .web-skills, .game-skills {
                border: 1px solid white;
                padding: 80px;
            }
        
            .web-skills {
                border-radius: 0px 60px 60px 60px;
            }
        
            .game-skills {
                border-radius: 60px 0px 60px 60px;
            }
        }
    }
    
    
    .featured-projects {
        
    }
}






