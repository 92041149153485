$breakpoints: (
  "xs": 340px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  "2xl": 1536px,
);

@function breakpoint($size) {
    @return map-get($breakpoints, $size);
}



#navbar {
    z-index: 99;
    position: fixed;
    left: 0;
    right: 0;
    background-color: transparent;
    padding: 1em;
    box-shadow: 0px -10px 20px 0px black;
    color: black;
    transition: all 200ms ease;
    transition-delay: 150ms;

    a {
        border-bottom: 1px solid transparent;

        &:hover {
            border-bottom: 1px solid;
        }
    }

    .btn-white {
        border-color: black;
    }
    
    .router-link-active {
        border-bottom: 1px solid !important;
        border-color: black;
        border-radius: 0;
    }


    .logo {
      border: 2px solid black;
      transition: all 0.2s ease;
    }
    svg {
      height: 20px;
      width: auto;
      path {
        fill: black;
        transition-delay: 150ms;
        transition-duration: 2s;
      }

      @media screen and (min-width: #{breakpoint(md)}) {
        height: 35px;
      }
    }

    button {
      background-color: #f7f7f7;
      color: black;
      border: 1px solid black;
      transition: all 0.2s ease;
      box-shadow: none;
      &:hover {
        background-color: #cacaca;
        box-shadow: 0px 0px 5px #3a3a3a;
        border: 1px solid black;
      }
    }


    &.bg-dark {
      background-color: black;
      color: white;

      .router-link-active {
        border-color: white;
      }
      path {
        fill: white;
      }

      .logo {
        border-color: white;
      }
  
    }

    @media screen and (max-width: 768px) {
        padding: 0.4em 1em;

        svg {
            // height: 30px;
        }
    }
}