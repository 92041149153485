.facts-section {
    .t-x-center {
        transition: animation 1s ease;
        animation: bounceUp 15s ease infinite;
    }
    
    .t-r {
        position: absolute;
        top: 4rem;
        left: calc(100% - (130px + 4rem));
        transition: animation 1s ease;
        animation: bounceTopRight 15s ease infinite;        
    }
    
    .r-y-center {
        transition: animation 1s ease;
        animation: bounceRightLeft 15s ease infinite;
    }
    
    .b-r {
        position: absolute;
        bottom: 4rem;
        right: 4rem;
        transition: animation 1s ease;
        animation: bounceBottomRight 15s ease infinite;
    }
    
    .b-x-center {
        transition: animation 1s ease;
        animation: bounceDown 15s ease infinite;
    }
    
    .b-l {
        position: absolute;
        bottom: 4rem;
        left: 4rem;
        transition: animation 1s ease;
        animation: bounceBottomLeft 15s ease infinite;
    }
    
    .l-y-center {
        transition: animation 1s ease;
        animation: bounceLeftRight 15s ease infinite;
    }
    
    .t-l {
        position: absolute;
        top: 4rem;
        left: 4rem;
        transition: animation 1s ease;
        animation: bounceTopLeft 15s ease infinite;
    }
    
    
    img {
        border-radius: 15px;
    }
    .rounded-box {
        height: 80px;
        width: 80px;
        
        &-inner {
            font-size: 14px;
            border-radius: 50%;
            height: 80px;
            width: 80px;
            height: 100%;
            width: 100%;
            color: white;
            letter-spacing: 0px;
            background-color: #9c27b0;

        }
        &.active {
            font-weight: 700;
            transition: all 2s linear;
            box-shadow: 0px 0px 10px #838383;
        }


        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20%;
            height: 20%;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.5); /* Wave color */
            transform: translate(-50%, -50%) scale(0);
            animation: wave 3.5s ease-in-out infinite;
            pointer-events: none; /* Prevent interaction */
          }
          
          @keyframes wave {
              0% {
                  transform: translate(-50%, -50%) scale(0);
                  opacity: 0.5;
              }
              30% {
                  transform: translate(-50%, -50%) scale(5); /* Extend to edges */
                  opacity: 0;
              }
              100% {
                  transform: translate(-50%, -50%) scale(5); /* Extend to edges */
                  opacity: 0;
              }
          }
    }
}


  
@keyframes bounceUp {
    0% { transform:scale(1);}
    10% { transform:scale(1.12);}
    20% { transform:scale(1);}
}

@keyframes bounceDown {
    40% { transform:scale(1);}
    50% { transform:scale(1.12);}
    60% { transform:scale(1);}
}

  @keyframes bounceTopLeft {
    70% { transform:scale(1);}
    80% { transform:scale(1.12);}
    90% { transform:scale(1);}
}

@keyframes bounceTopRight {
    10% { transform:scale(1);}
    20% { transform:scale(1.12);}
    30% { transform:scale(1);}
}

@keyframes bounceRightLeft {
    20% { transform:scale(1);}
    30% { transform:scale(1.12);}
    40% { transform:scale(1);}
}

@keyframes bounceLeftRight {
    60% { transform:scale(1);}
    70% { transform:scale(1.12);}
    80% { transform:scale(1);}
}

@keyframes bounceBottomRight {
    30% { transform:scale(1);}
    40% { transform:scale(1.12);}
    50% { transform:scale(1);}
}
@keyframes bounceBottomLeft {
    50% { transform:scale(1);}
    60% { transform:scale(1.12);}
    70% { transform:scale(1);}
}


.line-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .image-container {
    position: relative;
    z-index: 2; /* Ensure image is in the foreground */
  }
  
  .line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: initial;
    z-index: 1; /* Place the line behind the image and box 1 */
  }


.preview-img {
  border-radius: 15px;
  overflow: hidden;
}
