.screenshot-section {
    height: 60vh;
    display: flex;
  
    .scrollable-list {
      width: 30%;
      position: relative;
      height: 100%;
      overflow-y: auto;
  
      .scroll-indicator {
        position: absolute;
        height: 20px;
        z-index: 1;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
  
        &.top {
          top: 0;
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent);
        }
  
        &.bottom {
          bottom: 0;
          background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
        }
  
        &.visible {
            opacity: 1;
            position: sticky !important;
        }
      }
  
      .image-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem 0;
  
        .image-item {
          cursor: pointer;
          border: 2px solid transparent;
          transition: border-color 0.3s ease;
  
          &:hover {
            border-color: #0d0d0d;
          }
  
          &.active {
            border-color: #9c27b0;
          }
  
          img {
            max-width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  
    .active-image-display {
      width: 70%;
      display: flex;
      flex-direction: column;
      padding: 1rem;
  
      h2 {
        margin-bottom: 1rem;
      }
  
      .image-container {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
  
        img {
          max-width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  


  @media (max-width: 768px) {
    .scrollable-list {
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
  
      .image-list {
        flex-direction: row;
        display: inline-flex;
      }
    }
  
    .image-item {
      display: inline-block;
      width: auto;
      margin-right: 1rem;
    }
  }